<script setup>
import { ref } from "vue";
import BaseLayout from "@/app/layouts/BaseLayout.vue";

const categories = ref([]);

function onCategoryUpdate(data) {
  categories.value = data;
}
</script>

<template>
  <BaseLayout :categories="categories">
    <router-view @update:categories="onCategoryUpdate" />
  </BaseLayout>
</template>
