<script setup>
import { ref } from "vue";

// import { ref, computed } from "vue";
import BaseDropdown from "@/app/components/ui/BaseDropdown.vue";
import BaseIcon from "@/app/components/ui/BaseIcon.vue";

// import { locales } from "@/app/utils/locales";
import { useI18n } from "@/app/composables/useI18n";
import { countryMapping } from "../../../src/sportsbook/utils/general";

// import { loadLanguage } from "@/app/utils/i18n";

const { locales, loadLanguage, locale } = useI18n();

const baseDropdown = ref(null);
const emit = defineEmits(["close"]);

function changeLocale(langCode) {
  loadLanguage(langCode);
  emit("close", false);
  // baseDropdown.value.close();
}
</script>

<template>
  <!-- <BaseDropdown ref="baseDropdown" :show-down-icon="true"> -->
  <!-- <template #label>
      <div class="block">
 
        <img
          class="league-icon"
          :src="`/assets/images/flags/${countryMapping[locale.flag] || 'xx'}.svg`"
          alt=""
        />
      </div>
    </template> -->
  <!-- <template #panel> -->
  <div class="locales">
    <div
      v-for="(locale, index) in locales"
      :key="index"
      class="item"
      @click="changeLocale(locale.code)"
    >
      <!-- <BaseIcon :name="locale.flag" width="32" height="25" /> -->
      <img
        class="league-flag"
        :src="`/assets/images/flags/${countryMapping[locale.flag] || 'xx'}.svg`"
        alt=""
      />
      <span>
        {{ locale.name }}
      </span>
    </div>
  </div>
  <!-- </template> -->
  <!-- </BaseDropdown> -->
</template>

<style lang="scss" scoped>
:deep(.dropdown-panel) {
  width: 240px !important;
  right: -4px;
  top: 52px;

  &:after {
    left: 209px;
  }
}

.block {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: #333360;
  height: 40px;
  width: 40px;
  cursor: pointer;
}

@media screen and (max-width: 500px) {
  .block {
    width: initial;
    display: block;
    height: initial;
    background: transparent;
  }
}

.league-icon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  object-fit: cover;
}

@media screen and (max-width: 500px) {
  .league-icon {
    width: 18px;
    height: 18px;
    margin-top: 3px;
    margin-bottom: 4px;
  }
}

.league-flag {
  width: 32px;
  height: 25px;
  border: 1px solid white;
}

.locales {
  margin: 20px 10px;

  .item {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;

    span {
      margin-left: 25px;
    }
  }
  .item:hover{
    background:var(--be-divider)
  }
}
</style>
