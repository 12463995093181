import { useNavigation } from "../composables/useNavigations";
import { useTimeServer } from "../composables/useTimeServer";
import { useLogoBanner } from "../composables/useLogoBanner";
import { ping } from "../api";
import { api } from "@/services";
import { useUserService } from "@/user-account/composables/useUserService.ts";
import { useConfig } from "@/app/composables/useConfig";
import { useCurrencies } from "@/app/composables/useCurrencies";
import { useSportsdata } from "@/sportsbook/composables/useSportsdata";
import { useVirtualGamesService } from "@/virtual-games/composables/useVirtualGamesService";
import { useFreeBets } from "@/sportsbook/composables/useFreeBets";
import { useFreeSpins } from "@/sportsbook/composables/useFreeSpins";
import { useReferAndEarn } from "@/app/composables/useReferAndEarn.js";

const { setCurrencies } = useCurrencies();
const { setUser, isAuthenticated } = useUserService();
const { setSystemSettings, setRegistrationFlow,setBtcRate } = useConfig();
const { setSports } = useSportsdata();
const { setVirtualGames } = useVirtualGamesService();
const { setFreeBets } = useFreeBets();
const { setFreeSpins } = useFreeSpins();
const { setBanners, setLogo } = useLogoBanner();
const { setNavigation } = useNavigation();
const { setTimeServer } = useTimeServer();
const { setReferAndEarn } = useReferAndEarn();

function getCookie(cname) {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

let pingCount = 0;
async function pingServer() {
  const query = [
    "systemSettings",
    "currencies",
    "sports",
    "userAccount",
    "freeBets",
    "freeSpins",
    "virtualGames",
    "registrationFlow",
  ];

  pingCount += 1;

  if (query.length > 0) {
    const newData = await ping();

    const settings = newData.data.settings;

    setSystemSettings(settings);

    setRegistrationFlow(settings.registrationFlow);

    setBtcRate(newData.data.btc_usd_rate);

    setReferAndEarn(settings.referAndEarn);

    setCurrencies(newData.data.currencies);

    const logo = newData.data.banners.find(banner => banner.type === "logo");

    const logoUrl = logo?.image?.url ?? null;

    setLogo(logoUrl);

    setVirtualGames(newData.data.virtualGames);

    setTimeServer(newData.data.now);

    if (newData.data?.user) {
      setUser(newData.data?.user);
    }

    const banners = {
      casino: [],
      sports: [],
      virtuals: [],
      survivor: [],
    };

    newData.data.banners.forEach(banner => {
      if (banner.type === "casino") {
        banners.casino.push({
          name: banner.name,
          url: banner.image.url,
          file: null,
        });
      } else if (banner.type === "sports") {
        banners.sports.push({
          name: banner.name,
          url: banner.image.url,
          file: null,
        });
      } else if (banner.type === "virtuals") {
        banners.virtuals.push({
          name: banner.name,
          url: banner.image.url,
          file: null,
        });
      } else if (banner.type === "survivor") {
        banners.survivor.push({
          name: banner.name,
          url: banner.image.url,
          file: null,
        });
      }
    });

    setBanners(banners);

    setFreeSpins(newData.data.freeSpins);

    // Deprecated since phaseout of sportsbook
    setSports([]);

    setNavigation([]);

    setFreeBets([]);
  }
}

async function initPingService() {
  await pingServer();

  if (import.meta.env.VITE_PING === "true") {
    setInterval(async () => {
      await pingServer();
    }, 3000);
  }
}

export { getCookie, pingServer, initPingService };
