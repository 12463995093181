<script setup>
import { computed, onMounted, onUnmounted, ref } from "vue";
import { useRoute } from "@/app/router";

const emit = defineEmits(["sort"]);
const selectedSort = ref("popular"); // default sort option

const route = useRoute();

const sortOptions = computed(() => {
  return [
    { id: 1, name: "Popular", slug: "popular" },
    { id: 2, name: "New Releases", slug: "new_release" },
    { id: 3, name: "Alphabetical A-Z", slug: "alph_asc" },
    { id: 4, name: "Alphabetical Z-A", slug: "alph_dsc" },
  ];
});

async function onSortChange(e, sortOption) {
  selectedSort.value = sortOption.slug;
  emit("sort", selectedSort.value);
   isMenuOpen.value = false;
}

// Click outside functionality
const isMenuOpen = ref(false);
const menuRef = ref(null);

function handleClickOutside(event) {
  if (menuRef.value && !menuRef.value.contains(event.target)) {
    isMenuOpen.value = false;
  }
}

onMounted(() => {
  document.addEventListener("click", handleClickOutside);
});

onUnmounted(() => {
  document.removeEventListener("click", handleClickOutside);
});

function toggleMenu() {
  isMenuOpen.value = !isMenuOpen.value;
}
</script>

<template>
  <div ref="menuRef" class="select-provider" :class="{ 'menu-open': isMenuOpen }">
    <div class="select-box">
      <div class="right-box" @click="toggleMenu">
        <div>
          Sorted by<span v-if="selectedSort">:</span>
          <span v-if="selectedSort" class="selected-text">
            {{ sortOptions.find((option) => option.slug === selectedSort)?.name }}
          </span>
        </div>
        <img
          class="arrow"
          :src="`/assets/images/${isMenuOpen ? 'up-arrow' : 'down-white'}.svg`"
          alt=""
        />
      </div>
    </div>
    <div v-if="isMenuOpen" class="select-menu">
      <div class="col">
        <div v-for="item in sortOptions" :key="item.id" class="item">
          <label class="container">
            {{ item.name }}
            <input
              type="radio"
              name="sort"
              :checked="selectedSort === item.slug"
              @change="(e) => onSortChange(e, item)"
            />
            <span class="radio-checkmark" />
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.select-provider {
  position: relative;
  margin-left: 16px;
}

.selected-text {
  color: var(--be-primary-btn);
  margin-left: 4px;
}

.arrow {
  width: 16px;
}

.right-box {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 12px;
}

.select-box {
  border-radius: 8px;
  border: 1px solid var(--be-divider);
  background: var(--be-big-card-bg);
  display: flex;
  align-items: center;
  font-size: 15px;
  height: 47px;
  min-width: 250px;
  cursor: pointer;
  transition: border-color 0.2s ease;

  &:hover {
    border-color: var(--be-primary-btn);
  }

  .select-provider.menu-open & {
    border-color: var(--be-primary-btn);
  }

  .left-icon {
    cursor: pointer;
    background: var(--be-secondary-btn);
    height: 100%;
    display: flex;
    align-items: center;
    padding: 16px;
    margin-right: 15px;
  }
}

.select-menu {
  border-radius: 8px;
  border: 1px solid var(--be-small-card-bg);
  background: var(--be-big-card-bg);
  box-shadow: 0px 30px 80px 0px rgba(0, 0, 0, 0.25);
  position: absolute;
  right: 0;

  padding: 16px;
  width: 100%;
  max-height: 160px;
  overflow-y: auto;
  margin-top: 9px;
  z-index: 7;

  .col {
    margin-bottom: 12px;
  }

  .item {
    display: flex;
    flex-basis: 33.33%;
    gap: 0px 6px;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }

    .container {
      display: block;
      position: relative;
      padding-left: 23px;
      padding-top: 2px;
      cursor: pointer;
      font-size: 14px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      color: var(--White, #fff);
      font-family: Rubik;

      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .radio-checkmark {
      position: absolute;
      top: 1px;
      left: 0;
      height: 18px;
      width: 18px;
      border-radius: 50%;
      background-color: var(--be-small-card-bg);
      border: 1px solid #333360;
    }

    .container:hover input ~ .radio-checkmark {
      background-color: var(--be-small-card-bg);
    }

    .container input:checked ~ .radio-checkmark {
      background-color: var(--be-primary-btn);
      border: 1px solid var(--be-primary-btn);
    }

    .radio-checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    .container input:checked ~ .radio-checkmark:after {
      display: block;
    }

    .container .radio-checkmark:after {
      left: 6px;
      top: 6px;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: var(--be-primary-btn-label);
    }
  }
}

@media screen and (max-width: 500px) {
  .select-provider {
    position: relative;
    margin-left: 8px;
    width: 50%;
  }

  .select-menu {
    width: 100%;
    transform: translate(-50%, 0%);
    left: 50%;
    .item {
      flex: 50%;
    }
  }

  .select-box {
    min-width: 50%;
    height: 47px;
    border-radius: 8px;

    .right-box {
      width: 100%;
    }
  }
}
</style>
