<script setup>
import { computed, ref, watch } from "vue";
import VueHorizontal from "vue-horizontal";
import GameCard from "./GameCard.vue";
import { fetchGames } from "./api";
import BaseIcon from "@/app/components/ui/BaseIcon.vue";
import { useI18n } from "@/app/composables/useI18n";
import { router } from "@/app/router";

const props = defineProps({
  category: {
    type: Object,
    default: null,
  },

  search: {
    type: String,
    required: true,
  },
});

const loading = ref(false);
const games = ref([]);

const hasGames = computed(() => games.value.length > 0 && !loading.value);

watch(() => props.search, () => {
  updateGames();
});

async function updateGames() {
  const params = {
    categories: [props.category.id],
    per_page: 20,
    page: 1,
  };

  if (props.search) {
 
    params.search = props.search;
  }

  loading.value = true;

  const { data } = await fetchGames(params);
  games.value = data.data;

  loading.value = false;
}

updateGames();

const { t } = useI18n();

const horizontal = ref(null);

function prevSlide(event) {
  if (horizontal.value && typeof horizontal.value.prev === "function") {
    horizontal.value.prev(event);
  } else {
    console.error("Horizontal reference or next method is not defined");
  }
}

function nextSlide(event) {
  if (horizontal.value && typeof horizontal.value.next === "function") {
    horizontal.value.next(event);
  } else {
    console.error("Horizontal reference or next method is not defined");
  }
}

function handleViewAll(slug) {
  router.push({ name: "casino.category", params: { slug } });
}
</script>

<template>
  <section class="list-wrapper">
    <div class="list-header">
      <router-link
        :to="{ name: 'casino.category', params: { slug: category.slug } }"
        class="list-title"
      >
        <img v-if="category.image" :src="category.image?.url" alt="" width="24" height="24" />
        <BaseIcon v-else name="poker-cards" color="var(--be-disable-state)" />
        <span> {{ category.name }}</span>
      </router-link>
      <div v-if="hasGames" class="list-view" @click="() => handleViewAll(category.slug)">
        <div>{{ t("general.view-all") }}</div>
        <div :class="horizontal?.hasPrev ? '' : 'dim'" @click="(event) => prevSlide(event)">
          <img src="/assets/images/left-arrow-base-sliding.svg" alt="">
        </div>
        <div :class="horizontal?.hasNext ? '' : 'dim'" @click="(event) => nextSlide(event)">
          <img src="/assets/images/right-arrow-base-sliding.svg" alt="">
        </div>
      </div>
    </div>
    <VueHorizontal v-if="hasGames" ref="horizontal" :button="false">
      <div v-for="game in games" :key="game.slug">
        <div class="game-wrapper">
          <GameCard :game="game" />
        </div>
      </div>
    </VueHorizontal>
    <div v-else class="no-games">
      <p>No games found</p>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.no-games {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  font-size: 16px;
  color: var(--be-disable-state);
}
.dim {
  opacity: 0.3;
}

.list-view {
  display: flex;
  gap: 0 20px;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  color: var(--be-primary-btn);
  font-family: Rubik;
}

.list-header {
  display: flex;
  justify-content: space-between;
  margin-right: 0px;
  margin-bottom: 10px;
}

.list-wrapper {
  padding: 16px;
  margin: 10px 0px;
  background-color: var(--be-big-card-bg);
  border-radius: 8px;

  .list-title {
    display: flex;
    align-items: center;
    margin: 0 0 4px 2px;

    img {
      max-width: 24px;
      height: 24px;
    }

    span {
      margin: 4px 0 0 8px;
    }
  }

  .game-wrapper {
    display: flex;
    height: 100%;
    margin-right: 8px;
  }
}

@media screen and (max-width: 550px) {
  .list-wrapper {
    background: transparent;
    padding: 16px 0;
  }
}
</style>
