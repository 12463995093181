<script setup lang="ts">
import { type Ref, computed, onBeforeUnmount, onMounted, ref } from "vue";

import { useBetStore } from "../../../../store";
import LanguageSelect from "../LanguageSelect.vue";
import SidebarSection from "./SidebarSection.vue";
import { useI18n } from "@/app/composables/useI18n";
import { useConfig } from "@/app/composables/useConfig";
import { useSportsdata } from "@/sportsbook/composables/useSportsdata";
import { useAuth } from "@/user-account/composables/useAuth";
import { useVirtualGamesService } from "@/virtual-games/composables/useVirtualGamesService";
import BaseIcon from "@/app/components/ui/BaseIcon.vue";

const props = defineProps({
  categories: {
    type: Array,
    default: () => [],
  },
});

const { isDrawerOpen, appDivisions, appDivision } = useConfig();

const { sports } = useSportsdata();
const { virtualGames } = useVirtualGamesService();

const { isAuthenticated } = useAuth();
const { t } = useI18n();

const language = ref(false);

const rootItems: SidebarMenuItem[] = [];
const store = useBetStore();
const baseItems = ref([
  {
    id: -101,
    title: t("sportsbook.mybets"),
    icon: "bets",
    route: { name: "" },
    click: true,
    imageUrl: null,
    handleClick: () => {
      if (isAuthenticated()) {
        store.handleShowBetModal();
        store.setSection("bet");
      } else {
        store.handleShowLogin();
      }
    },
  },
]);

const supportItems = ref([
  {
    id: -101,
    title: t("general.live_support"),
    icon: "live",
    route: { name: "" },
    click: true,
    imageUrl: null,
    handleClick: () => store.handleShowChatModal(),
  },
  {
    id: 103,
    title: t("general.language"),
    icon: "globe",
    route: { name: "" },
    click: true,
    imageUrl: null,
    handleClick: () => (language.value = !language.value),
  },
]);

if (appDivisions.value.includes("sportsbook")) {
  rootItems.push({
    id: -101,
    title: t("general.sports"),
    icon: "sports",
    imageUrl: null,
    route: { name: "sportsbook" },
    click: false,
    handleClick: undefined,
  });
}
if (appDivisions.value.includes("casino")) {
  rootItems.push({
    id: -102,
    title: t("general.casino"),
    icon: "lobby",
    imageUrl: null,
    route: { name: "casino" },
    click: false,
    handleClick: undefined,
  });
  rootItems.push({
    id: -101,
    title: t("general.virtualGames"),
    icon: "sports",
    imageUrl: null,
    route: { name: "virtualGames" },
    click: false,
    handleClick: undefined,
  });
}

const casinoItems: Ref<SidebarMenuItem[]> = computed(() => {
  return props.categories.map((category) => {
    return {
      id: category.id,
      title: t(`casino.${category.name}`),
      icon: null,
      route: {
        name: "casino.category",
        params: { slug: category.slug },
      },
      imageUrl: category.image?.url ?? null,
    };
  });
});

const sportbookItems: Ref<SidebarMenuItem[]> = computed(() => {
  return sports.value.map((sport) => {
    return {
      id: sport.id,
      title: t(`sports.${sport.slug}`),
      icon: sport.icon,
      imageUrl: null,
      route: {
        name: !sport.showAllMatches ? "sports.today" : "sport.competition",
        params: {
          sportSlug: sport.slug,
        },
      },
    };
  });
});

const virtualGamesItems: Ref<SidebarMenuItem[]> = computed(() => {
  return virtualGames.value.map((game) => {
    return {
      id: game?.id,
      title: t(`general.${game?.code}`),
      icon: game?.code,
      imageUrl: null,
      route: {
        name: "virtualGames.game",
        params: { code: game?.code },
      },
    };
  });
});

const screenWidth = ref(window.innerWidth);

function handleResize() {
  screenWidth.value = window.innerWidth;
}

onMounted(() => {
  window.addEventListener("resize", handleResize);
  handleResize(); // Initial check
  isDrawerOpen.value = screenWidth.value > 500 ? isDrawerOpen.value : false;
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", handleResize);
});

const DrawerValue = screenWidth.value >= 568 ? true : isDrawerOpen;

function handleDepostBet() {
  if (isAuthenticated()) {
    store.handleShowBetModal();
    store.setSection("wallet");
    store.setWalletTab("deposit");
  } else {
    store.handleShowLogin();
  }
}
</script>

<template>
  <v-card style="z-index: 11">
    <v-layout>
      <v-navigation-drawer
        v-model="DrawerValue"
        :expand-on-hover="false"
        :style="`top: 60px; border: none; width:${
          isDrawerOpen ? '' : '270px'
        }; transform:translateX(${
          isDrawerOpen ? '0%' : screenWidth <= 568 && !isDrawerOpen ? '-200%' : null
        })`"
        class="side-bar-col"
        :rail="screenWidth >= 568 ? isDrawerOpen : false"
      >
        <div v-if="language" class="modal-overlay" @click="() => (language = false)">
          <LanguageSelect
            :class="` ${!isDrawerOpen ? 'language-selector' : 'shrink-language-selector'}`"
            @close="() => (language = false)"
          />
        </div>
        <div :class="isDrawerOpen ? 'wrapper' : ''" style="margin-bottom: 150px">
          <SidebarSection
            :menu-items="baseItems"
            :show-separator="screenWidth >= 568 ? !isDrawerOpen : true"
          />
          <!-- :expand-on-hover="false"
        :style="`top: 58px; background: #19183b !important; color: white; border: none; width:${
          isDrawerOpen ? '' : '270px'
        }`"
        :rail="isDrawerOpen" -->
          <SidebarSection
            v-if="appDivision === 'casino'"
            :menu-items="casinoItems"
            :title="t('general.casino')"
            :gameHeading="true"
            :show-separator="screenWidth >= 568 ? !isDrawerOpen : true"
          />
          <SidebarSection
            v-if="appDivision === 'sportsbook'"
            :menu-items="sportbookItems"
            :title="t('general.sports')"
            :gameHeading="true"
            :show-separator="screenWidth >= 568 ? !isDrawerOpen : true"
          />
          <SidebarSection
            v-if="appDivision === 'virtualGames'"
            :menu-items="virtualGamesItems"
            :title="t('general.virtualGames')"
            :gameHeading="true"
            :show-separator="screenWidth >= 568 ? !isDrawerOpen : true"
          />

          <SidebarSection
            :menu-items="supportItems"
            :title="t('general.virtualGames')"
            :show-separator="screenWidth >= 568 ? !isDrawerOpen : true"
          />
          <div class="deposit-btn" @click="handleDepostBet">
            <!-- <img
            src="/assets/images/bets/deposit-active.svg"
            :class="`${isDrawerOpen ? 'ml-0' : 'ml-_11'}`"
            alt=""
            /> -->
            <div :class="`${isDrawerOpen ? 'ml-0 tooltip-container' : 'ml-_11'}`">
              <BaseIcon name="deposit" size="18" color="var(--be-primary-btn-label)" />
              <span v-if="isDrawerOpen" class="tooltip">{{ "Deposit" }}</span>
            </div>
            <div v-if="screenWidth >= 568 ? !isDrawerOpen : true">
              {{ t("finance.deposit") }}
            </div>
          </div>
        </div>
      </v-navigation-drawer>
    </v-layout>
  </v-card>
</template>

<style lang="scss" scoped>
@import "@/assets/scss/thin-scrollbar.scss";

.modal-overlay {
  position: fixed;
  top: -60px;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  width: 1000vh;
  height: 100vh;
}

.side-bar-col {
  background: var(--be-big-card-bg);
  color: var(--be-primary-text-color);
}

.deposit-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  gap: 8px;
  height: 40px;
  background: var(--be-primary-btn);
  color: var(--be-primary-btn-label);
  margin: 10px;
  font-size: 14px;
  font-family: Rubik-med;
  opacity: 0.9;
  cursor: pointer;
  position: relative;
  margin-bottom: 80px;
}
.deposit-btn:hover {
  opacity: 1;
}
.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  position: absolute;
  left: calc(100% + 18px); //Position to the right of the button
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  color: #000;
  padding: 6px 12px;
  border-radius: 6px;
  font-size: 14px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;

  transition: opacity 0.4s ease, visibility 0.4s ease;
}

.tooltip::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent white transparent transparent;
}
@media screen and (min-width: 550px) {
  .tooltip-container:hover .tooltip {
    opacity: 1;
    visibility: visible;
  }
}


.ml-0 {
  margin-left: 0px;
}
.ml-_11 {
  margin-left: -11px;
}

.language-selector {
  flex-shrink: 0;
  margin: 0 10px 0 0;
  position: absolute;
  left: 271px;
  top: 150px;
  width: 186px;

  background: var(--be-small-card-bg);
  overflow: auto;
}

.shrink-language-selector {
  left: 55px;
  flex-shrink: 0;
  margin: 0 10px 0 0;
  position: absolute;
  top: 150px;
  width: 186px;

  background: var(--be-small-card-bg);
  overflow: auto;
}
.sidenav {
  margin-top: 67px;
  padding: 20px;
  width: 90%;
  max-width: 300px;
  position: fixed;
  overflow-y: scroll;
  top: 0;
  left: 0;
  height: calc(100% - 67px);
  background: var(--be-background-color);
  pointer-events: auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transform: translateX(-100%);
  transition: transform 0.3s;
  will-change: transform;
  z-index: 99;
  &.opened {
    transform: translateX(0);
  }
}

@media screen and (min-width: 500px) {
  .wrapper {
    position: absolute;
    width: 100%;
  }
}
// .sidenav.loaded {
//   visibility: visible !important;
// }
</style>
