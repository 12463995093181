<script setup>
import { useBetStore } from "../../../../store";
import BaseIcon from "@/app/components/ui/BaseIcon.vue";
import { useConfig } from "@/app/composables/useConfig";
import { router } from "@/app/router";
import { useAuth } from "@/user-account/composables/useAuth";
import { useI18n } from "@/app/composables/useI18n";

const props = defineProps({
  menuItems: {
    type: Array,
    default: () => [],
  },
  showSeparator: {
    type: Boolean,
    default: true,
  },
  title: {
    type: String,
    default: "",
  },
  gameHeading: {
    type: Boolean,
    default: false,
  },
});

const store = useBetStore();

const { appDivision, isDrawerOpen } = useConfig();

const { isAuthenticated } = useAuth();
const { t, locale } = useI18n();

async function clicked(menuItem) {
  if (menuItem?.click) {
    menuItem?.handleClick();
  } else if (props.title == "Virtuals" && !isAuthenticated()) {
    store.handleShowLogin();
  } else {
    router.push(menuItem.route);
  }
}
</script>

<template>
  <div :class="`separator ${props.showSeparator ? '' : 'none'}`">
    {{ props.title }}
  </div>

  <div class="sidebar-block">
    <div
      v-if="gameHeading"
      :class="showSeparator ? 'game-heading' : 'game-heading show-seperator-game'"
    >
      <img class="game-image" :src="`/assets/images/${appDivision}.svg`" alt="" />
      <div v-if="showSeparator">
        {{ t(`general.${appDivision}`) }}
        {{ t("general.games") }}
      </div>
    </div>
    <div
      v-for="menuItem in props.menuItems"
      :key="menuItem?.id"
      :class="
        appDivision === menuItem?.route?.name
          ? 'menu-item--active'
          : !showSeparator
          ? 'show-seperator menu-item'
          : 'menu-item'
      "
      class="menu-item--active"
      @click="clicked(menuItem)"
    >
      <div :class="isDrawerOpen ? 'tooltip-container' : ''">
        <div>
          <img
            v-if="menuItem.imageUrl"
            :src="menuItem.imageUrl"
            class="menu-item-img"
            alt=""
            width="16"
            height="16"
          />
          <BaseIcon
            v-else
            color="white"
            :name="menuItem.icon ?? 'poker-cards'"
            size="16"
            class="icon"
          />
        </div>
        <span class="tooltip" v-if="isDrawerOpen">{{ menuItem?.title }}</span>
      </div>
      <span v-if="showSeparator" class="title">{{ menuItem?.title }}</span>
      <div v-if="menuItem?.icon == 'globe' && showSeparator" class="language-icons">
        <div class="language-title">
          <div>:</div>
          <div>{{ locale.name }}</div>
        </div>
        <img src="/assets/images/right-icon.svg" alt="" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.menu-item-img {
  margin-right: 10px;
}

.separator {
  margin-top: 10px;
  display: flex;
  transition: all 300ms ease-in-out;
  justify-content: center;
  color: #999;
  text-transform: uppercase;
  font-size: 0px;
  letter-spacing: 2px;
  .tablet & {
    opacity: 1;
  }
}
.language-icons {
  display: flex;
  width: 52%;
  justify-content: space-between;
}

.language-title {
  display: flex;
  gap: 5px;
  font-family: Rubik;
  font-size: 16px;
}

// tootltip.......................

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  position: absolute;
  left: calc(100% + 10px); //Position to the right of the button
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  color: #000;
  padding: 6px 12px;
  border-radius: 6px;
  font-size: 14px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;

  transition: opacity 0.4s ease, visibility 0.4s ease;
}

.tooltip::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent white transparent transparent;
}
@media screen and (min-width: 550px) {
  .tooltip-container:hover .tooltip {
    opacity: 1;
    visibility: visible;
  }
}
/* Show tooltip on hover */

// tooltip........
.game-heading {
  text-align: center;
  display: flex;
  padding: 8px 0 8px 16px;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid #1a1b38;
  font-family: Rubik-med;
  font-size: 16px;
  .game-image {
    width: 16px;
    height: 16px;
  }
}

.sidebar-block {
  background: var(--be-small-card-bg);
  border-radius: 8px;
  margin: 0px 10px;
}

.none {
  opacity: 0;
}

.menu-item {
  padding: 8px 0 8px 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  opacity: 1;

  &--active {
    opacity: 1;
  }

  .icon {
    margin-right: 10px;
    width: 24px;
  }
  .title {
    font-size: 16px;
    font-family: Rubik;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-transform: capitalize;
  }
  &.grayed-out {
    opacity: 0.5;
    cursor: initial;
  }
}

.show-seperator {
  padding-left: 9px;
}

.show-seperator-game {
  padding-left: 9px;
  border-bottom: 1px solid #1a1b38;
}
.menu-item--active:hover {
  background: var(--be-divider);
  border-radius: 8px;
}
</style>
