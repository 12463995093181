<script setup>
import { useBetStore } from "/store";
import { computed } from "vue";
import { useStartTimer } from "../hooks/timerFunction";
import { handleShare } from "../utils/shareFunction";
const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  container: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(["selectGame"]);

const store = useBetStore();
//  timmer Function

const dateTime = new Date(props.item?.entryCloseTime);
const calculateCountdown = useStartTimer(dateTime,props.container);

function handleSelectGame(item) {
  emit("selectGame", item);
}

const isMobile = computed(() => {
  return store.getScreenWidth < 512;
});
</script>

<template>
  <div class="tournament-content">
    <div :class="container ? 'tournament-name' : 'mb-container'">
      <span>{{ item.name }}</span>
      <img v-if="!container" src="/assets/images/info-circle.svg" alt="info" />
      <img v-if="container" src="../../assets/images/ticket-2.svg" alt="info" />
    </div>
    <div class="tournament-info-container">
      <div class="tournament-info">
        <div class="mbl-free-text">
          <div class="free-text">Always free to enter.</div>
          <div v-if="isMobile" class="share-btn" @click="handleShare()">
            <div>Share</div>
            <img src="/assets/images/share.svg" alt="info" />
          </div>
        </div>

        <div v-if="container" class="entry-close-container">
          <div v-if="!isMobile" class="last-entry">Entry Closes : {{ calculateCountdown }}</div>
          <div class="share-btn-container">
            <div v-if="!isMobile" class="share-btn" @click="handleShare()">
              <div>Share</div>
              <img src="/assets/images/share.svg" alt="info" />
            </div>
            <div class="entry-time">
              <div class="entry-class">Entry closes:</div>
              <div class="">
                {{ calculateCountdown }}
              </div>
            </div>
            <button class="make-picks-btn" @click="handleSelectGame(item)">
              {{ item?.hasPrediction ? "Edit" : "Make" }} picks now
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.tournament-content {
  margin-bottom: 20px;
}

.tournament-name {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: start;
  align-items: center;
  background: linear-gradient(to right, #e6943f, #8364e8);
  padding: 20px;
  border-radius: 8px 8px 0px 0px;
  color: white;
  font-weight: 500;
  gap: 16px;
}
.mb-container {
  display: flex;
  align-items: center;

  justify-content: space-between;
  align-items: center;
  background: linear-gradient(to right, #e6943f, #8364e8);
  padding: 20px;
  border-radius: 8px 8px 0px 0px;
  color: white;
  font-weight: 500;
}
.free-text {
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 20px;
}

.tournament-info-container {
  background: var(--be-small-card-bg);
  border-radius: 0px 0px 8px 8px;
  padding: 20px;
}

.tournament-info {
  font-size: 16px;
  font-family: Poppins;
  font-weight: 500;
}

.share-btn-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 18px;
}

.share-btn {
  display: flex;
  background-color: var(--be-divider);
  width: 100px;
  height: 51px;
  align-items: center;
  justify-content: center;
  gap: 0 10px;
  border-radius: 8px;
  font-family: Poppins-med;
  font-weight: 500;
}

.make-picks-btn {
  background-color: var(--be-primary-btn);
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  height: 51px;
  width: 145px;
  font-family: Poppins-med;
}

.entry-time {
  display: none;
}

.instructions,
.last-entry {
  color: #9ca3af;
  font-family: Poppins-reg;
  font-size: 16px;
}

@media (max-width: 550px) {
  .make-picks-btn {
    font-size: 14px;
    border-radius: 8px;
    height: 38px;
    width: 145px;
  }

  .tournament-name {
    padding: 10px 20px;
    font-size: 14px;
    gap: 10px;
  }
  .mb-container {
    padding: 10px 20px;
    font-size: 14px;
  }
  .tournament-content {
    margin-bottom: 15px;
  }

  .entry-time {
    display: block;
  }

  .entry-class {
    color: rgba(255, 255, 255, 0.5);
    font-family: Poppins-reg;
  }

  .mbl-none {
    display: none;
  }

  .share-btn {
    width: 72px;
    height: 32px;
    font-size: 12px;
  }

  .free-text {
    border: none;
    padding-bottom: 0px;

    margin-bottom: 0px;
  }

  .mbl-free-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .entry-close-container {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    margin-top: 16px;
  }
  .tournament-info {
    font-size: 14px;
  }
}
</style>
